<template>
  <div>
    <div @click="showCalendar = true">
      <slot :format-date="formatDate">
        <!-- 默认内容 -->
      </slot>
    </div>
    <BaseAZCalendar
      v-model:show="showCalendar"
      :default-selected-date="defaultSelectedDate"
      @confirm="onConfirmCalendar"
    />
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<Props>(), {
  formatter: 'DD MMM, YYYY',
  isDetail: false,
})

const dayjs = useDayjs()

interface Props {
  formatter?: string
}

const showCalendar = ref(false)

const filterStore = useFilterConditionsStore()

const selectDate = computed(() => {
  return dayjs(filterStore.filterDateRef.get(props.isDetail)).format('YYYY-MM-DD')
})

const formatDate = computed(() => {
  return dayjs(selectDate.value).format(props.formatter)
})

const defaultSelectedDate = computed(() => {
  return new Date(selectDate.value)
})

function onConfirmCalendar(value: string) {
  // selectDate.value = value
  filterStore.filterDateRef.set(props.isDetail, value as any)
}
</script>
