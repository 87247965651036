<template>
  <div class="bg-#07B883">
    <van-calendar
      v-model:show="show"
      class="az-calendar"
      :style="{ height: '100%' }"
      :title="t('title')"
      :show-confirm="false"
      :round="false"
      :show-mark="false"
      :row-height="44"
      :show-subtitle="false"
      :lazy-render="false"
      :default-date="defaultSelectedDate"
      :max-date="$dayjs().add(1, 'month').toDate()"
      :close-on-popstate="true"
      close-icon-position="top-left"
      color="#121212"
      @confirm="onConfirm"
    >
      <template #title>
        <div class="align-middle" text="lg left" p="y-2 l-12">
          {{ t('title') }}
        </div>
      </template>
      <template #month-title="slotProps">
        <div class="mt-8 pl-4 text-left text-2xl text-[#121212]">
          {{ $dayjs(slotProps.date).format('MMM YYYY') }}
        </div>
      </template>
    </van-calendar>
  </div>
</template>

<style lang="scss">
.az-calendar {
  .van-popup__close-icon {
    top: 12px;
  }
  .van-calendar__header-title {
    height: 60px;
  }
  .van-calendar__selected-day {
    background-color: #07b883;
    color: #fff;
    border-radius: 50%;
  }
  .van-calendar__day {
    margin-bottom: 10px;
  }
}
</style>

<script setup lang="ts">
interface Props {
  defaultSelectedDate?: any
}

withDefaults(defineProps<Props>(), {
  defaultSelectedDate: new Date(),
})

const emit = defineEmits(['confirm'])

const { t } = useI18n()

const show = defineModel('show', {
  type: Boolean,
  default: false,
})

function onConfirm(value: string) {
  show.value = false
  emit('confirm', value)
}
</script>

<i18n lang="json">
{
  "en": {
    "title": "Select Date"
  }
}
</i18n>
